import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import kebabCase from "lodash/kebabCase";
import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import { trackEvent } from "../util/google-analytics";

class TagsRoute extends React.Component {
  render() {
    const tags = this.props.data.allMarkdownRemark.group;
    const { title, subtitle, author } = this.props.data.site.siteMetadata;
    const description = subtitle;
    const url = typeof window !== "undefined" ? window.location.href : "";
    const origin = typeof window !== "undefined" ? window.location.origin : "";
    const profilePic = require("../pages/logo.png");
    const headTitle = `All Tags - ${title}`;
    return (
      <Layout>
        <div>
          <Helmet>
            <title>{headTitle}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={url} />
            <meta property="og:url" content={`${url ? url : origin}`} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={headTitle} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={`${origin}${profilePic}`} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={author.twitter} />
            <meta name="twitter:title" content={headTitle} />
            <meta name="twitter:description" content={description} />
            <meta name="monetization" content="$ilp.uphold.com/QzG6Ww2rKUzq" />

          </Helmet>
          <Sidebar {...this.props} />
          <div className="content">
            <div className="content__inner">
              <div className="page">
                <h1 className="page__title">Tags</h1>
                <div className="page__body">
                  <div className="tags">
                    <ul className="tags__list">
                      {tags.map((tag) => (
                        <li key={tag.fieldValue} className="tags__list-item">
                          <Link
                            to={`/tags/${kebabCase(tag.fieldValue)}/`}
                            className="tags__list-item-link"
                            onClick={(e) =>
                              trackEvent(
                                e,
                                "Tags Page",
                                "Click",
                                `${tag.fieldValue}`
                              )
                            }
                          >
                            {tag.fieldValue} ({tag.totalCount})
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default TagsRoute;

export const pageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        feature {
          label
          path
        }
        deployed {
          label
          path
        }
        author {
          name
          email
          telegram
          twitter
          github
          rss
          vk
        }
      }
    }
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
